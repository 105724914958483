<template>
  <div class="page">
    <div class="box" v-loading.fullscreen.lock="fullscreenLoading">
      <div class="left">
        <!-- 头部 -->
        <div>
          <!-- 通知栏 -->
          <div class="top" v-if="notice && isShow">
            <notice-bar
              class="topNoticeBar"
              :scrollable="true"
              :speed="notice.length > 50 ? 50 : notice.length"
              :outview="true"
              :text="notice"
              bg-color="transparent"
              color="#000"
              :left-icon="$http.baseURL + '/static/images/web/2x.png'"
            />
            <img @click="isShow = false" class="guan" src="@image/guanbi.svg" />
          </div>

          <!-- 视频 -->
          <!-- <video class="video" controls :poster="detail.video_images" :src="detail.video"
						controlsList="nodownload"></video> -->
          <!-- 视频浏览量信息条 -->
          <!-- <div class="videoTip" @click="xin.goUrl('/index-newsDetail?id=' + detail.id)">
						<span>{{ detail.info_count }}浏览</span>
						<span class="time">{{ detail.create_time | formaTime }}</span>
					</div> -->

          <!-- 广告轮播图 -->
          <advBanner
            v-if="bannerArr.length > 0"
            :bannerArr="bannerArr"
            carouselWidth="890px"
            carouselHeight="400px"
          ></advBanner>
          <!-- 广告轮播图俄end -->
        </div>

        <!-- 考试相关介绍tab -->
        <div class="group">
          <div
            class="box"
            :style="{
              background: item.background,
              boxShadow: '0px 3px 6px 0px rgba(' + item.boxShadow + ',0.30)',
            }"
            v-for="(item, index) in ceramics"
            :key="index"
            @click="pageJump(item.title)"
          >
            <img class="ceramics" :src="item.img" />
            <span>{{ item.title }}</span>
          </div>
        </div>

        <!-- 左侧下方 -->
        <!-- 有内容 -->
        <template v-if="newList.length">
          <div class="left-group" v-loading="listLoading">
            <list
              :discoveryList="discoveryList"
              :page="1"
              :page-size="10"
              v-show="page == 1"
            ></list>
            <div
              v-for="(item, i) in newList"
              :key="item.id"
              @click="xin.goUrl('/index-newsDetail?id=' + item.id)"
            >
              <div v-if="item.video">
                <div class="content2">
                  <div class="img-s">
                    <img class="banner2" :src="item.video_images" />
                    <div class="rectangular">
                      <img
                        :src="$http.baseURL + '/static/images/web/2x1.png'"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="right">
                    <span class="title">{{ item.title }}</span>
                    <span class="subTitle">{{ item.subtitle }}</span>
                    <div class="subTitle2">
                      <span>{{ item.info_count | pageviews }}浏览</span>
                      <span class="time">{{
                        item.create_time | formaTime
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="content2">
                  <img class="banner2" :src="item.images[0]" />
                  <div class="right">
                    <span class="title">{{ item.title }}</span>
                    <span class="subTitle">{{ item.subtitle }}</span>
                    <div class="subTitle2">
                      <span>{{ item.info_count | pageviews }}浏览</span>
                      <span class="time">{{
                        item.create_time | formaTime
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 广告 -->
              <advertise
                :newsList="newsList"
                :newsListNum="newsListNum"
                :i="i"
                @advertiseSure="advertiseSure"
                :page="page"
                :pageSize="pageSize"
              ></advertise>

              <!-- 广告 -->
            </div>
          </div>

          <!-- 分页 -->
          <div class="foot-page" v-if="total > 10">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="page"
              :page-size="10"
              layout="prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </template>

        <!-- 无内容 -->
        <div v-else class="boxNull">
          <div class="null-box">
            <img
              :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
              alt=""
            />
            <div class="title">暂无数据</div>
          </div>
        </div>
      </div>

      <!-- 右侧金刚区 -->
      <div class="right">
        <!-- tab栏 -->
        <div class="box">
          <div class="bottom">
            <div
              class="icon"
              v-for="(item, index) in list"
              :key="index"
              @click="goUrl(item, index)"
            >
              <img class="policy" :src="item.img" />
              <span>{{ item.span }}</span>
            </div>
          </div>
        </div>

        <!-- 信息区 -->
        <messageTwo></messageTwo>
      </div>
    </div>

    <!-- 对话框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="560px"
      :before-close="handleClose"
    >
      <span>学习请先进行身份认证</span>
      <div class="popupsButton">
        <span @click="dialogVisible = !dialogVisible" class="popupsJudgment"
          >取消</span
        >
        <span @click="go" class="popupsJudgment">认证</span>
      </div>
    </el-dialog>
    <!-- 对话框end -->

    <!-- 隐私服务是不是已经更新 -->
  </div>
</template>

<script>
// import { mapState, mapActions } from 'vuex'
import messageTwo from "@components/message/message";
import advBanner from "@components/common/advBanner";
import advertise from "@components/common/advertise";
import { mapState, mapActions } from "vuex";
import { NoticeBar } from "vue-easy-notice-bar";
import list from "@components/common/list";

export default {
  components: {
    messageTwo,
    NoticeBar,
    advBanner,
    advertise,
    list,
  },
  data() {
    return {
      newsList: [],
      dislistsList: [],
      newsListNum: 0,
      zero: 0,
      notice: "", // 通知栏
      isShow: true, // 控制通知栏的显示和隐藏
      bannerArr: [],
      dialogVisible: false, // 控制对话框的显示与隐藏
      fullscreenLoading: true, // loading层
      list: [
        {
          img: require("@image/policy.png"),
          span: "卫生法规",
          path: "/index-examSettingPolicy",
          type: 1,
        },
        {
          img: require("@image/examination.png"),
          span: "考纲教材",
          path: "/index-syllabus",
          type: 2,
        },
        {
          img: require("@image/study.png"),
          span: "在线学习",
          path: "/index-eLearning",
        },
        {
          img: require("@image/exam.png"),
          span: "模拟考试",
          path: "/index-mockExam",
        },
        // {
        //   img: require("@image/specialist.png"),
        //   span: "专家委员",
        //   path: "/index-expertCommittee",
        // },
      ],
      pageSize: 10,
      ceramics: [
        {
          img: require("@image/award_research.png"),
          title: "有奖调研",
          background: "#FB7A5F",
          boxShadow: "251,122,95",
        },
        {
          img: require("@image/guide_analysis.png"),
          title: "指南解析",
          background: "#FDA80D",
          boxShadow: "253,168,13",
        },
        {
          img: require("@image/case_sharing.png"),
          title: "病例分享",
          background: "#5EBFF4",
          boxShadow: "94,191,244",
        },
        {
          img: require("@image/drug_bank.png"),
          title: "药品库",
          background: "#B18CFD",
          boxShadow: "177,140,253",
        },
        {
          img: require("@image/paper_sharing.png"),
          title: "论文分享",
          background: "#8ED147",
          boxShadow: "142,209,71",
        },
        {
          img: require("@image/living.png"),
          title: "直播",
          background: "#1264ff",
          boxShadow: "18, 100, 255",
        },
      ],
      newList: [], // 新闻列表
      detail: {}, // 视频相关信息
      listLoading: false, // 区域加载
      page: 1,
      total: 0,
      discoveryList: [],
      limit: 10,
    };
  },

  async mounted() {
    // this.userInfo.company.status = 0
    // this.fullscreenLoading = true;
    await this.dislists();
    this.getVideoDetail();
    await this.getNotice();
    await this.getBanner();
    await this.getIndexData();
    this.fullscreenLoading = false;
    await this.advList();
    this.discoverDislists();
  },

  computed: {
    ...mapState("userManagement", ["userInfo"]),
  },

  methods: {
    ...mapActions("userManagement", ["getUserInfo"]),

    // 首页推荐位
    async discoverDislists() {
      let res = await this.$findApi.discoverDislists();
      res.data.forEach((item) => {
        item.images = JSON.parse(item.images);
      });
      if (res.code == 200) {
        this.discoveryList = res.data;
      } else {
        this.$message.error(res.message);
      }
    },

    async dislists() {
      let res = await this.$findApi.dislistsInfo({});

      if (res.code == 200) {
        this.dislistsList = res.data.data;
        this.disTotal = res.data.total;
      }
    },

    // 广告列表
    async advList() {
      let res = await this.$findApi.adv_list({});
      if (res.code == 200) {
        this.newsList = res.data.list;
        this.newsListNum = res.data.rules.num;
        this.newsList.forEach((item) => {
          item.isShow = false;
        });
      }
    },

    // 关闭广告对话框确认
    advertiseSure(index) {
      this.newsList[index].isShow = true;
    },

    // 通告栏
    async getNotice() {
      let res = await this.$findApi.findNotice({
        type: 0,
      });
      if (res.code == 200) {
        let content = res.data;
        content = content.replace(/[\r\n]/g, ""); //去掉回车换行
        content = content.replace(/;/g, "  "); // 去掉最后;
        content = content.replace(/<[^>]+>/g, ""); // 去掉html标签;
        content = content.replace(/&nbsp/g, ""); // 去掉&nbsp空格符号;
        content = content.replace(/\s+/g, ""); //去掉空格
        this.notice = content;
      }
    },

    // 轮播数据
    async getBanner() {
      let res = await this.$findApi.banner({
        position: 1,
      });
      if (res.code == 200) {
        this.bannerArr = res.data;
      }
    },

    // pageSize改变时会触发
    async handleSizeChange(val) {
      this.page = val;
      this.listLoading = true;
      await this.getIndexData();
      this.listLoading = false;
    },
    // currentPage改变时会触发
    async handleCurrentChange(val) {
      this.page = val;
      this.listLoading = true;
      await this.getIndexData();
      this.listLoading = false;
      // 滚动到顶部
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    // 认证
    go() {
      this.dialogVisible = false;
      this.$router.push({
        path: "/authentication",
      });
    },

    // 获取新闻列表
    async getIndexData() {
      let res = await this.$newsApi.getNewsLists({
        page: this.page,
        name: this.$route.query.title,
      });
      if (res.code == 200) {
        this.newList = res.data.data;
        // this.total = res.data.total;
        this.total = res.data.total;
        // this.dislistsList=res.data.data
        // for(var i=0;i<this.dislistsList.length;i++){
        // // if(!this.newList&&!this.dislistsList){
        // if(this.newList!=null&&this.newList!="null"&&this.dislistsList!=null&&this.dislistsList!="null")
        // 	this.newList.splice(this.dislistsList[i].home_page_set,this.zero,this.dislistsList[i])
        // 		this.total = res.data.total+ this.disTotal;
        // // }
        // }
        // // this.newList=JSON.parse(this.newList)
        // // this.disTotal=res.data.total
      }
    },

    // 获取视频详情
    async getVideoDetail() {
      let res = await this.$newsApi.getnewsVideo();
      if (res.code == 200) {
        this.detail = res.data;
      }
    },

    // 右侧路径跳转
    async goUrl(item, i) {
      await this.getUserInfo();
      // 非在线学习
      if (i != 2) {
        this.$router.push({
          path: item.path,
        });
      } else {
        // 在线学习登录状态下
        if (this.userInfo.company && this.userInfo.company.status == 1) {
          this.$router.push({
            path: item.path,
          });
        } else {
          // 在线学习非登录状态下

          if (!localStorage.getItem("token")) {
            return this.$message.error("请登录");
          } else {
            this.dialogVisible = true;
          }
        }
      }
    },

    // 关闭前的回调，会暂停Dialog的关闭
    handleClose(done) {
      done();
    },

    // 跳转页面
    pageJump(title) {
      if (title == "有奖调研") {
        this.$router.push("/research");
      }
      if (title == "指南解析") {
        this.$router.push("/found-guideAnalysis");
      }
      if (title == "病例分享") {
        this.$router.push("/found-caseSharing");
      }
      if (title == "论文分享") {
        this.$router.push("/found-paperSharing");
      }
      if (title == "药品库") {
        this.$router.push("/drugs");
      }
      if (title == "直播") {
        this.$router.push("/liveBroadcastList");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

// video::-internal-media-controls-download-button {
//    display:none;
// }
// video::-webkit-media-controls-enclosure {
// 	overflow:hidden;
// }
// video::-webkit-media-controls-panel {
// 	width: calc(100% + 30px);
// }

::v-deep {
  .text-container {
    width: 100% !important;
  }

  .el-dialog {
    display: flex;
    flex-direction: column;
    padding: 70px 80px;
    box-sizing: border-box;
    margin-top: 320px;

    > .el-dialog__body {
      font-size: 24px;
      color: #333333;
      text-align: center;
    }

    .popupsButton {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;

      > .popupsJudgment {
        &:last-child {
          background: $--themeColor;
          border: none;
          color: #ffffff;
        }

        width: 170px;
        height: 50px;
        background: #ffffff;
        border: 1px solid $--themeColor;
        border-radius: 26px;
        color: $--themeColor;
        font-size: 18px;
        text-align: center;
        line-height: 50px;
        margin-top: 60px;
      }
    }
  }

  .el-carousel__item .banner {
    width: 890px;
    height: 400px;
    object-fit: cover;
  }

  .el-carousel__arrow {
    display: none;
  }
}

.page {
  .foot-page {
    height: 40px;
    text-align: center;
    width: 100%;
  }

  .boxNull {
    margin-top: 60px;
    text-align: center;
  }

  .videoTip {
    background: #f2f2f2;
    padding: 10px;
    color: #8f8f8f;
    font-size: 16px;
    cursor: pointer;

    .time {
      margin-left: 20px;
    }
  }

  > .box {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;

    > .left {
      display: flex;
      flex-direction: column;
      width: 890px;

      .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 890px;
        height: 40px;
        background: #dbfbf8;
        padding: 10px;
        box-sizing: border-box;
        color: $--themeColor;
        font-size: 16px;
        margin-bottom: 20px;

        > .message {
          width: 20px;
          height: 20px;
          object-fit: cover;
          margin-right: 10px;
        }

        .topNoticeBar {
          width: 100%;
          padding-left: 0;
        }

        > .guan {
          cursor: pointer;
          width: 12px;
          height: 12px;
          object-fit: cover;
          margin-left: auto;
        }
      }

      > .group {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        flex-wrap: wrap;
        justify-content: space-between;

        > .box {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 280px;
          height: 74px;
          border-radius: 5px;
          box-shadow: 0px 3px 6px 0px rgba(251, 122, 95, 0.3);
          font-size: 24px;
          color: #ffffff;
          background: #fb7a5f;
          margin-bottom: 25px;

          > .ceramics {
            width: 36px;
            height: 36px;
            object-fit: cover;
            margin-right: 11px;
          }
        }
      }

      > .left-group {
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;

        .banner2 {
          width: 270px;
          height: 200px;
          object-fit: cover;
          border-radius: 10px;
          margin-right: 20px;
        }

        .title {
          font-size: 18px;
          color: #333333;
          margin-bottom: 10px;
          margin-right: 20px;
        }

        .content {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid #f2f2f2;
          padding-bottom: 30px;
          box-sizing: border-box;
          margin-bottom: 30px;
          font-size: 18px;
          margin-top: 10px;

          .banner {
            width: 145px;
            height: 100px;
            border-radius: 10px;
          }

          .right {
            display: flex;
            flex-direction: column;
            color: #8f8f8f;
            font-size: 16px;
            margin-left: 20px;
            line-height: 1.8;

            .subTitle2 {
              display: flex;
              flex-direction: row;
              margin-top: auto;

              > .time {
                margin-left: 20px;
              }
            }
          }
        }

        .content2 {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid #f2f2f2;
          padding-bottom: 30px;
          box-sizing: border-box;
          margin-bottom: 30px;

          .img-s {
            position: relative;
            width: 200px;
            height: 150px;
            margin-right: 20px;

            .rectangular {
              width: 40px;
              height: 40px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              opacity: 0.7;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .banner2 {
            width: 200px;
            height: 150px;
            border-radius: 10px;
            object-fit: cover;
          }

          .right {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            line-height: 1.8;
            color: #8f8f8f;
            font-size: 16px;

            .title {
              color: #333333;
              font-size: 18px;
            }

            .subTitle2 {
              margin-top: auto;

              .time {
                margin-left: 20px;
              }
            }
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          // border-bottom: 1px solid #f2f2f2;
          // padding-bottom: 30px;
          box-sizing: border-box;
          // margin-bottom: 30px;
          line-height: 1.8;
          color: #8f8f8f;
          font-size: 18px;

          .title {
            color: #333333;
            font-size: 24px;
          }

          .subTitle2 {
            margin-top: 20px;

            .time {
              margin-left: 20px;
            }
          }
        }
      }
    }

    > .right {
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      > .box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 294px;
        background: #f2f2f2;
        padding: 15px;
        box-sizing: border-box;
        margin-bottom: 20px;

        > .bottom {
          flex-wrap: wrap; //换行
          display: flex;
          flex-direction: row;
          width: 264px;
          // height: 199px;
          background: #ffffff;
          font-size: 14px;
          color: #333333;
          border-radius: 5px;

          > .icon {
            cursor: pointer;
            word-break: normal;
            margin-bottom: 14px;
            margin-top: 14px;
            display: flex;
            flex-direction: column;
            align-items: center;

            &:nth-child(4) {
              margin-top: 0;
            }

            &:nth-child(5) {
              margin-top: 0;
            }

            > .policy {
              width: 88px;
              height: 88px;
              object-fit: cover;
            }

            > span {
              margin-top: -7px;
            }
          }
        }
      }
    }
  }
}

.shoucanghover {
  color: #e60012;
}

.iconzan2 {
  color: #e60012;
}

.videoTip div:nth-child(2) {
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

// .topCheng {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
// }

.topCheng {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
