<template>
  <el-carousel
    :width="carouselWidth"
    :height="carouselHeight"
    :interval="3000"
    arrow="always"
    trigger="click"
    :loop="true"
    indicator-position="outside"
  >
    <el-carousel-item
      v-for="item in bannerArr"
      :key="item.id"
      :style="{ width: carouselWidth, height: carouselHeight }"
    >
      <!-- <video
        style="width: 100%; height: 100%"
        v-if="item.video && item.video != 0 && item.type == 2"
        class="video"
        controls
        :poster="item.images"
        :src="item.video"
        controlsList="nodownload"
        @click="jumpTo(item.url, item.id, item.type)"
        mode="aspectFill"
      ></video> -->
      <!-- fill / contain / cover / none / scale-down -->
      <el-image
        style="width: 100%; height: 100%"
        :src="item.images"
        class="video"
        fit="cover"
        @click="jumpTo(item.url, item.id, item.type)"
      ></el-image>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  props: {
    // 轮播图数据
    bannerArr: {
      type: Array,
      default() {
        return [];
      },
    },

    // 轮播图的宽度
    carouselWidth: {
      type: String,
      default: "",
    },

    // 轮播图的高度
    carouselHeight: {
      type: String,
      default: "",
    },
  },

  methods: {
    // 新闻和广告的路径跳转
    jumpTo(url, id, type) {
      if (type == 4) {
        return;
      }
      if (type != 3) {
        this.$router.push({
          path: "/index-asdvertising",
          query: {
            is_adv: id,
          },
        });
      } else {
        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-carousel__item {
  text-align: center;
}
.video {
  // position: absolute;
  width: 100%;
  height: 100%;
  // height:90%;
  // left: 0;
  // top: 50%;
  // transform: translateY(-50%);
}
</style>
